<template>
  <div>
    <div class="title">吉林音像出版社</div>
    <div class="tg-sectionhead">
      <div>联系电话:0431-81629660</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
}
</script>

<style scoped>
.title {
  font-size: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 19px;
}

.tg-sectionhead {
  margin: 20px 0 0 0;
  padding: 0 0 10px 0;
  border-bottom: none;
}

.tg-sectionhead > div {
  border-left: 5px solid #f05000;
  font-size: 16px;
  padding-left: 10px;
}
</style>
